//
// user.scss
// Use this to write your custom SCSS
//
.main-row {
  border-bottom: 10px solid #efefef;
}

.carousel-control.left {
  background: none;
}

.carousel-control.right {
  background: none;
}

.carousel .left > i,
.carousel .right > i {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.carousel .right > i {
  left: auto;
  right: 20px;
}

.carousel-inner > .item > a > img,
.carousel-inner > .item > img,
.img-responsive, .thumbnail a > img,
.thumbnail > img {
  margin: auto;
}

.carousel-caption {
  bottom: 10%;
  border-radius: 0;
  width: 90%;
  left: 5%;
  padding-top: 0;
  background-color: rgba(0, 0, 0, .5);
  padding-bottom: 4px;
  padding-top: 4px;
  border-radius: 6px;
}

.carousel-indicators [data-bs-target] {
  background-color: #3498db;
}

/** VUE.js EFFECTS begin **/


/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */
{
  transform: translateX(10px);
  opacity: 0;
}

.bounce-enter-active {
  animation: bounce-in .5s;
}

.bounce-leave-active {
  animation: bounce-in .5s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

/** Vue.js effects end **/

.price {
  font-size: 3.6rem;
}

.price-cents {
  vertical-align: super;
  font-size: 1.5rem;
}

.price-month {
  font-size: 1.5rem;
  font-style: italic;
}

#buttons-container > div > div.paypal-powered-by {
  display: none !important;
  visibility: hidden;
}

ul.errors li {
  color: red;
}
